@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    @apply border-0 ring-0 focus:ring-0 p-0;
  }
}

* {
  font-family: 'Eudoxus Sans';
}

.stackedLayer {
  grid-row: 1;
  grid-column: 1;
}

.moveable-vertical {
  width: 4px !important;
}

.moveable-horizontal {
  height: 4px !important;
}

.moveable-line {
  background: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
}

html {
  scrollbar-color: rgba(107, 114, 128, var(--tw-bg-opacity)) transparent;
  scrollbar-width: thin;

  /*
    Disable padding on html element when radix dropdown is open 
    There no prop to disable it so have to do here
  */
  padding: 0px !important;
}
html::-webkit-scrollbar {
  width: 4px;
  height: 6px;
  border-radius: 1rem;
}

html::-webkit-scrollbar-thumb {
  background: rgba(107, 114, 128, var(--tw-bg-opacity));
  border-radius: 1rem;
}

html::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar {
  scrollbar-color: rgba(107, 114, 128, var(--tw-bg-opacity)) transparent;
  scrollbar-width: thin;
}

.scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 6px;
  border-radius: 1rem;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: rgba(107, 114, 128, var(--tw-bg-opacity));
  border-radius: 1rem;
}

.scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.tl-container {
  background-color: transparent !important;
}

.disable-tldraw .tl-container,
.disable-tldraw .tl-container * {
  pointer-events: none;
  user-select: none;
}
